import { Injectable, signal } from '@angular/core';
import {
  argbFromHex,
  themeFromSourceColor,
  Hct,
} from '@material/material-color-utilities';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  systemTheme = signal<'light' | 'dark'>('light');
  theme = signal<'light' | 'dark' | 'system'>('system');
  sideNavState = signal<'opened' | 'closed'>(this.getInitialSideNavState());

  tones = [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 95, 98, 99, 100,
  ];

  set color(color: string) {
    if (color === 'default') {
      const element = document.documentElement;
      element.classList.remove('custom');
      localStorage.removeItem('@theme:color');
      return;
    }

    this.generateThemeColors(color);
    localStorage.setItem('@theme:color', color);
  }

  get color() {
    const regex = /#?([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/g;
    const color = localStorage.getItem('@theme:color');
    return color?.match(regex) ? color : 'default';
  }

  get themeMode() {
    return this.theme() === 'system' ? this.systemTheme() : this.theme();
  }

  get logo() {
    if (this.themeMode === 'dark') return '/assets/logo_dark.svg';
    return '/assets/logo.svg';
  }

  get avatar() {
    if (this.themeMode === 'dark') return 'assets/default_avatar_dark.png';
    return 'assets/default_avatar.png';
  }

  constructor() {
    this.getAppTheme();
    if (this.color !== 'default') this.generateThemeColors(this.color);
  }

  isMobileDevice(): boolean {
    return window.innerWidth <= 1024;
  }

  getInitialSideNavState(): 'opened' | 'closed' {
    return this.isMobileDevice() ? 'closed' : 'opened';
  }

  getAppTheme() {
    const theme = localStorage.getItem('@theme:mode') ?? 'system';

    switch (theme) {
      case 'light':
      case 'dark':
      case 'system':
        this.theme.set(theme);
        const element = document.documentElement;
        const className = theme === 'system' ? this.systemTheme() : theme;
        element.className = element.className.includes('custom')
          ? `custom ${className}`
          : className;
        break;
    }
  }

  toggleTheme() {
    switch (this.theme()) {
      case 'light':
        this.theme.set('system');
        break;
      case 'dark':
        this.theme.set('light');
        break;
      case 'system':
        this.theme.set('dark');
        break;
    }

    localStorage.setItem('@theme:mode', this.theme());
    const element = document.documentElement;
    element.className = element.className.includes('custom')
      ? `custom ${this.themeMode}`
      : this.themeMode;
  }

  generateThemeColors(color: string) {
    const argb = argbFromHex(color);
    const theme = themeFromSourceColor(argb);

    const allTones: { name: string; value: string }[] = [];

    for (const keyValue of Object.entries(theme.palettes)) {
      const [key, value] = keyValue;
      const name = key === 'neutralVariant' ? 'neutral-variant' : key;
      const tones = this.generateTones(name, value.keyColor.toInt());
      allTones.push(...tones);
    }

    const element = document.documentElement;

    for (const tone of allTones) {
      element.style.setProperty(tone.name, tone.value);
    }

    element.classList.add('custom');
  }

  generateTones(name: string, argb: number) {
    const hct = Hct.fromInt(argb);
    const tones: { name: string; value: string }[] = [];

    for (const tone of this.tones) {
      const toneHct = Hct.from(hct.hue, hct.chroma, tone);
      const toneArgb = toneHct.toInt();
      const hex = `#${toneArgb.toString(16).padStart(8, '0').slice(2)}`;
      tones.push({ name: `--custom-${name}-${tone}`, value: hex });
    }

    return tones;
  }
}
